import React from "react";
import { Dropdown, Layout, Space } from "antd";
import SidebarLayout from "./SidebarLayout";
import ContentLayout from "./ContentLayout";
import FooterLayout from "./FooterLayout";
import kurdish from "../../assets/images/kurd.png";
import arabic from "../../assets/images/iraq.png";
import english from "../../assets/images/eng.png";
import { useDispatch, useSelector } from "react-redux";
import { Change_LangugeHeader, change_Lang } from "../../action/Helper_action";
const { Header } = Layout;
const LayoutSystem = ({ children }) => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.HelperReducer.lang);
  const User = useSelector((state) => state.AuthReducer.User);
  const Change_Language_Kurdish = (e, language) => {
    e.preventDefault();
    localStorage.setItem("key_lang", "krd");
    dispatch(Change_LangugeHeader(language));
  };
  const Change_Language_English = (e, language) => {
    e.preventDefault();
    localStorage.setItem("key_lang", "eng");
    dispatch(Change_LangugeHeader(language));
  };
  const Change_Language_Arabic = (e, language) => {
    e.preventDefault();
    localStorage.setItem("key_lang", "arb");
    dispatch(Change_LangugeHeader(language));
  };
  const items = [
    {
      key: "1",
      label: (
        <span onClick={(e) => Change_Language_Kurdish(e, "Kurdish")}>
          Krd{" "}
          <img
            src={kurdish}
            style={{
              width: `20px`,
              position: "relative",
              display: "inline-block",
            }}
            alt="img"
            className="d-inline justify-content-center"
          />
        </span>
      ),
    },
    {
      key: "2",
      label: (
        <span onClick={(e) => Change_Language_Arabic(e, "Arabic")}>
          Arb{" "}
          <img
            src={arabic}
            style={{
              width: `20px`,
              position: "relative",
              display: "inline-block",
            }}
            alt="img"
            className="d-inline justify-content-end"
          />
        </span>
      ),
      // icon: <SmileOutlined />,
    },
    {
      key: "3",
      label: (
        <span onClick={(e) => Change_Language_English(e, "English")}>
          Eng{" "}
          <img
            src={english}
            style={{
              width: `20px`,
              position: "relative",
              display: "inline-block",
            }}
            alt="img"
            className="d-inline justify-content-end"
          />
        </span>
      ),
    },
  ];
  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <SidebarLayout />
      <Layout className="site-layout">
        <Header
          style={{
            padding: 0,
            color: "white",
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            paddingRight: 30,
            background: "#000",
          }}
        >
          <label style={{ color: "white" }} className=" m-auto">
            Pin Code : {User.PinCode}
          </label>
          <Dropdown
            menu={{
              items,
              className: "",
            }}
          >
            <span onClick={(e) => e.preventDefault()}>
              <Space
                className={`${lang === "en" ? "" : "fk"}`}
                style={{ cursor: "pointer" }}
              >
                <img
                  src={
                    lang === "en" ? english : lang === "arb" ? arabic : kurdish
                  }
                  style={{
                    width: `20px`,
                    position: "relative",
                    display: "inline-block",
                  }}
                  alt="img"
                  className="d-inline justify-content-end"
                />
                {dispatch(change_Lang("Languages"))}
              </Space>
            </span>
          </Dropdown>
        </Header>
        <ContentLayout children={children} />
        <FooterLayout />
      </Layout>
    </Layout>
  );
};

export default LayoutSystem;
