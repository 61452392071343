import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { NumericFormat } from "react-number-format";
import { Input, Form, Divider, Select, Space, Button, Drawer } from "antd";
import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import { change_Lang } from "../../action/Helper_action";
import setToken from "../../util/SetToken";
import Instance from "../../util/Instance";
import ShowImageModel from "./ShowImageModel";
import Compressor from 'compressorjs';
const { Option } = Select;
const AddProduct = ({ GetItems, isModalVisible, handleCancel }) => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.HelperReducer.lang);
  const [img_model, setimg_model] = useState(false);
  const [ImagePath, setImagePath] = useState("");
  const [Name, setName] = useState("");
  const [Manufacture, setManufacture] = useState("");
  const [Price, setPrice] = useState("");
  const [Currency, setCurrency] = useState("");
  const [Quantity, setQuantity] = useState("");
  const [note, setnote] = useState("");

  const NewPage = () => {
    setName("");
    setManufacture("");
    setImagePath("");
    setCurrency("");
    setQuantity("");
    setnote("");
    setPrice("");
  };
  const onSaveProduct = async () => {
    if (localStorage.token) {
      setToken(localStorage.getItem("token"));
    }
    const config = {
      "content-type": "application/json",
    };
    try {
      const data = {
        Price:
          Price !== "" ? parseFloat(Price.toString().replace(/,/g, "")) : 0,
        Name,
        Manufacture,
        ImagePath,
        Currency,
        Quantity,
        note,
      };
      if (Name === "") {
        Swal.fire({
          title: "OPS",
          icon: "error",
          html: `<b class="fk">
            ${dispatch(change_Lang("Please Check Inputs"))}
          </b>`,
          confirmButtonColor: "#EA994A",
          confirmButtonText: "Ok",
          timer: 1300,
          iconColor: "darkred",
        });
      } else {
        await Instance.post(`/api/v1/AddItem`, data, config);
        handleCancel();
        NewPage();
        Swal.fire({
          title: "Good job!",
          html: `<b class="fk">${dispatch(
            change_Lang("You successfully tainted your work")
          )}</b>`,
          icon: "success",
          confirmButtonText: dispatch(change_Lang("success")),
          buttonsStyling: { fontFamily: "fk" },
          confirmButtonColor: "#EA994A",
          timer: 3000,
          iconColor: "#363636",
        });
        setTimeout(() => {
          GetItems();
        }, 3000);
      }
    } catch (error) {}
  };
  const _handleImgChange = (files) => {
    let reader = new FileReader();
    let file = files[0];

    // Compress the image using compressorjs
    new Compressor(file, {
      quality: 0.6, // Adjust quality as needed (0.6 means 60% quality)
      success(result) {
        // 'result' is the compressed Blob
        const compressedFile = new File([result], 'compressed.jpg', {
          type: 'image/jpeg',
        });

    // Use the compressed file or its data URL as needed
    // Example: Set the compressed image as the image path
        reader.onloadend = () => {
          let img = reader.result;
          setImagePath(img);
        };

        // Read the compressed file as a data URL
        reader.readAsDataURL(compressedFile);
      },
      error(err) {
        console.error(err.message);
      },
    });
  };
  const Open_ImageModel = (i) => {
    setimg_model(!img_model);
  };
  const OnDelete_Image = (i) => {
    setImagePath("");
  };
  const Close_ImageModel = () => {
    setimg_model(false);
  };
  const onChange_Currency = (value) => {
    setCurrency(value);
  };
  const containerStyle = {
    textAlign: "center",
  };
  return (
    <Drawer
      title={dispatch(change_Lang("Create a new Product"))}
      width={720}
      onClose={handleCancel}
      className={`${lang === "en" ? "text-center" : " fk"}`}
      open={isModalVisible}
      style={containerStyle}
      extra={
        <Space>
          <Button
            onClick={handleCancel}
            className={`${lang === "en" ? "" : "fk"}`}
          >
            {dispatch(change_Lang("cancel"))}
          </Button>
          <Button
            onClick={onSaveProduct}
            type="primary"
            className={`${lang === "en" ? "" : "fk"}`}
            style={{ background: "#EA994A" }}
          >
            {dispatch(change_Lang("Add"))}
          </Button>
        </Space>
      }
    >
      <Form layout="vertical">
        <div className="row">
          <div className="col-md-4">
            <label
              className={`${
                lang === "en"
                  ? " d-flex justify-content-center"
                  : "fk  d-flex justify-content-center"
              }`}
              style={{
                fontSize: "14px",
                color: "#052f43",
                fontWeight: `${lang === "en" ? "" : "bold"}`,
              }}
            >
              {dispatch(change_Lang("Product Brand"))}
            </label>
            <Input
              size="middle"
              value={Manufacture}
              name="Manufacture"
              type="text"
              onChange={(e) => setManufacture(e.target.value)}
              style={{
                fontSize: "16px",
              }}
            />
          </div>
          <div className="col-md-4">
            <label
              className={`${
                lang === "en"
                  ? " d-flex justify-content-center"
                  : "fk  d-flex justify-content-center"
              }`}
              style={{
                fontSize: "14px",
                color: "#052f43",
                fontWeight: `${lang === "en" ? "" : "bold"}`,
              }}
            >
              {dispatch(change_Lang("Product Name"))}
            </label>
            <Input
              size="middle"
              value={Name}
              name="Name"
              type="text"
              onChange={(e) => setName(e.target.value)}
              style={{
                fontSize: "16px",
              }}
            />
          </div>
          <div className="col-md-4">
            <label
              className={`${
                lang === "en"
                  ? " d-flex justify-content-center"
                  : "fk  d-flex justify-content-center"
              }`}
              style={{
                fontSize: "14px",
                color: "#052f43",
                fontWeight: `${lang === "en" ? "" : "bold"}`,
              }}
            >
              {dispatch(change_Lang("Quantity"))}
            </label>
            <Input
              size="middle"
              value={Quantity}
              name="Quantity"
              type="number"
              onChange={(e) => setQuantity(e.target.value)}
              style={{
                fontSize: "16px",
              }}
            />
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-6">
            <label
              className={`${
                lang === "en"
                  ? "d-flex justify-content-center"
                  : " d-flex justify-content-center fk"
              }`}
              style={{
                fontSize: "14px",
                color: "#052f43",
                fontWeight: `${lang === "en" ? "" : "bold"}`,
              }}
            >
              {dispatch(change_Lang("price"))}
            </label>
            <NumericFormat
              style={{
                fontSize: "14px",
              }}
              displayType="input"
              thousandSeparator={true}
              value={Price === 0 ? "" : Price}
              name="Price"
              onChange={(e) => setPrice(e.target.value)}
              required
              min={1}
              step={1}
              className={`form-control text-center`}
              autoComplete="off"
            />
          </div>
          <div className="col-md-6">
            <label
              className={`${
                lang === "en"
                  ? "d-flex justify-content-center"
                  : " d-flex justify-content-center fk"
              }`}
              style={{
                fontSize: "14px",
                color: "#052f43",
                fontWeight: `${lang === "en" ? "" : "bold"}`,
              }}
            >
              {dispatch(change_Lang("Currency Type"))}
            </label>
            <Select
              onChange={onChange_Currency}
              value={Currency}
              defaultValue={dispatch(change_Lang("Choose"))}
              className={`${lang === "en" ? "col-12" : " col-12 fk"}`}
            >
              <Option value={""} className="fk">
                {dispatch(change_Lang("Choose"))}
              </Option>
              <Option value={"$"} className="fk">
                {dispatch(change_Lang("dolar"))}
              </Option>
              <Option value={"IQD"} className="fk">
                {dispatch(change_Lang("dinar"))}
              </Option>
            </Select>
          </div>
        </div>
        <br />
        <br />
        <div className="row">
          <label
            className={`${
              lang === "en"
                ? "d-flex justify-content-center"
                : " d-flex justify-content-center fk"
            }`}
            style={{
              fontSize: "14px",
              color: "#052f43",
              fontWeight: `${lang === "en" ? "" : "bold"}`,
            }}
          >
            {dispatch(change_Lang("Description"))}
          </label>
          <textarea
            value={note}
            name="note"
            onChange={(e) => setnote(e.target.value)}
            autoComplete="off"
            className={`${lang === "en" ? "form-control" : "form-control  fk"}`}
            style={{
              fontSize: "14px",
            }}
            rows={4}
            cols={130}
          ></textarea>
        </div>
        <Divider
          className={`${
            lang === "en"
              ? "d-flex justify-content-center"
              : " d-flex justify-content-center fk"
          }`}
          style={{
            fontSize: "16px",
            color: "#052f43",
            fontWeight: `${lang === "en" ? "" : "bold"}`,
          }}
        >
          {dispatch(change_Lang("Product Image"))}
        </Divider>
        <div className="row">
          <div className="col-10 col-md-12">
            {ImagePath === "" ? (
              <Dropzone
                onDrop={(files) => _handleImgChange(files)}
                multiple={true}
                maxSize={80000000000}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div
                      {...getRootProps()}
                      style={{
                        height: "120px",
                        border: "1px dashed lightgray",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <input {...getInputProps()} />
                      <i
                        className="fas fa-upload mr-5"
                        style={{
                          fontSize: "24px",
                          color: "#052f43",
                        }}
                      ></i>
                    </div>
                  </section>
                )}
              </Dropzone>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <img
                    onClick={Open_ImageModel}
                    style={{
                      width: "200px",
                      height: "200px",
                      borderRadius: 20,
                    }}
                    src={`${ImagePath}`}
                    alt={`${ImagePath}`}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Form>
      {img_model === false ? null : (
        <ShowImageModel
          Close_Models={Close_ImageModel}
          OnDelete_Image={OnDelete_Image}
          ImagePath={ImagePath}
        />
      )}
    </Drawer>
  );
};

export default AddProduct;
