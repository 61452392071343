import React from "react";
import { QRCode } from "antd";
import { useSelector } from "react-redux";
import logo from "../../assets/images/logo_black.png";
// import { change_Lang } from "../../action/Helper_action";
const PrintQR = () => {
  //   const dispatch = useDispatch();
  const user = useSelector((state) => state.AuthReducer.User);
  return (
    <>
      <div className="row">
        <div className="col-6 d-flex justify-content-center mt-2">
          <QRCode
            errorLevel="H"
            size={400}
            iconSize={400 / 4}
            value={`http://al-ardh.com/${user && user.PinCode}`}
            icon={logo}
            bgColor="white"
            color="#EA994A"
          />
        </div>
        <div className="col-6 d-flex justify-content-center mt-2">
          <QRCode
            errorLevel="H"
            size={400}
            iconSize={400 / 4}
            value={`http://al-ardh.com/${user && user.PinCode}`}
            icon={logo}
            bgColor="white"
            color="#EA994A"
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-6 d-flex justify-content-center">
          <h1 className="fk" style={{ fontSize: 20, fontWeight: "bold" }}>
            .بۆ بینینی بەرهەمەکانمان سکانی ئەمە بکە
          </h1>
        </div>
        <div className="col-6 d-flex justify-content-center">
          <h1 className="fk" style={{ fontSize: 20, fontWeight: "bold" }}>
            .بۆ بینینی بەرهەمەکانمان سکانی ئەمە بکە
          </h1>
        </div>
      </div>
      <br />
      <br />
      <br />
      <div className="row">
        <div className="col-6 d-flex justify-content-center mt-2">
          <QRCode
            errorLevel="H"
            size={400}
            iconSize={400 / 4}
            value={`http://al-ardh.com/${user && user.PinCode}`}
            icon={logo}
            bgColor="white"
            color="#EA994A"
          />
        </div>
        <div className="col-6 d-flex justify-content-center mt-2">
          <QRCode
            errorLevel="H"
            size={400}
            iconSize={400 / 4}
            value={`http://al-ardh.com/${user && user.PinCode}`}
            icon={logo}
            bgColor="white"
            color="#EA994A"
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-6 d-flex justify-content-center">
          <h1 className="fk" style={{ fontSize: 20, fontWeight: "bold" }}>
            .بۆ بینینی بەرهەمەکانمان سکانی ئەمە بکە
          </h1>
        </div>
        <div className="col-6 d-flex justify-content-center">
          <h1 className="fk" style={{ fontSize: 20, fontWeight: "bold" }}>
            .بۆ بینینی بەرهەمەکانمان سکانی ئەمە بکە
          </h1>
        </div>
      </div>
      <br />
      <br />
      <br />
      <div className="row">
        <div className="col-6 d-flex justify-content-center mt-2">
          <QRCode
            errorLevel="H"
            size={400}
            iconSize={400 / 4}
            value={`http://al-ardh.com/${user && user.PinCode}`}
            icon={logo}
            bgColor="white"
            color="#EA994A"
          />
        </div>
        <div className="col-6 d-flex justify-content-center mt-2">
          <QRCode
            errorLevel="H"
            size={400}
            iconSize={400 / 4}
            value={`http://al-ardh.com/${user && user.PinCode}`}
            icon={logo}
            bgColor="white"
            color="#EA994A"
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-6 d-flex justify-content-center">
          <h1 className="fk" style={{ fontSize: 20, fontWeight: "bold" }}>
            .بۆ بینینی بەرهەمەکانمان سکانی ئەمە بکە
          </h1>
        </div>
        <div className="col-6 d-flex justify-content-center">
          <h1 className="fk" style={{ fontSize: 20, fontWeight: "bold" }}>
            .بۆ بینینی بەرهەمەکانمان سکانی ئەمە بکە
          </h1>
        </div>
      </div>
    </>
  );
};
export default PrintQR;
