import React, { useState } from "react";
import styles from "./Login.module.css";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Login, SignUp } from "../../action/Auth_action";
import Swal from "sweetalert2";
const LoginForm = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.AuthReducer.token);
  const [CompanyName, setCompanyName] = useState("");
  const [OwnerName, setOwnerName] = useState("");
  const [UserName, setUserName] = useState("");
  const [phone_no1, setphone_no1] = useState("");
  const [Password, setPassword] = useState("");
  // log in states
  const [L_UserName, setL_UserName] = useState("");
  const [L_Password, setL_Password] = useState("");
  const SignUp_User = (e) => {
    e.preventDefault();
    const user = {
      CompanyName,
      OwnerName,
      UserName,
      phone_no1,
      CityId: "1",
      Password,
    };
    if (
      CompanyName === "" ||
      OwnerName === "" ||
      UserName === "" ||
      phone_no1.toString().length === 0 ||
      Password.toString().length === 0
    ) {
      Swal.fire({
        title: "OPS",
        text: "تکایە خانەکان پڕبکەوە پڕبکەوە",
        icon: "error",
        html: `<b class="fk">تکایە خانەکان پڕبکەوە پڕبکەوە</b>`,
        confirmButtonText: "Ok",
        buttonsStyling: { fontFamily: "fk" },
        confirmButtonColor: "#EA994A",
        timer: 2000,
        iconColor: "#363636",
      });
    } else {
      dispatch(SignUp(user));
    }
  };
  const SignIn_User = (e) => {
    e.preventDefault();

    if (L_UserName === "" || L_Password.toString().length === 0) {
      Swal.fire({
        title: "OPS",
        text: "تکایە وشەی نهێنی و تێپەڕە وشە پڕبکەوە",
        icon: "error",
        html: `<b class="fk">تکایە وشەی نهێنی و تێپەڕە وشە پڕبکەوە</b>`,
        confirmButtonText: "Ok",
        buttonsStyling: { fontFamily: "fk" },
        confirmButtonColor: "#EA994A",
        timer: 2000,
        iconColor: "#363636",
      });
    } else {
      const user = {
        UserName: L_UserName,
        Password: L_Password,
      };
      dispatch(Login(user));
    }
  };
  if (token) {
    console.log("token", token);
    return <Redirect to="/list-products" />;
  }
  return (
    <div className={styles.body}>
      <div className={styles.section}>
        <div className={styles.container}>
          <div className={`row ${styles.full_height}  justify-content-center`}>
            <div className="col-12 text-center align-self-center py-5">
              <div
                className={`${styles.section}  pb-5 pt-5 pt-sm-2 text-center`}
              >
                <h6 className="mb-0 pb-3">
                  <span>بەشداربوون</span>
                  <span>چوونەژوورەوە</span>
                </h6>
                <input
                  className={styles.checkbox}
                  type="checkbox"
                  id="reg-log"
                  name="reg-log"
                />
                <label htmlFor="reg-log"></label>
                <div className={`${styles.card_3d_wrap} mx-auto`}>
                  <div className={styles.card_3d_wrapper}>
                    <div className={styles.card_front}>
                      <div className={styles.center_wrap}>
                        <div className={`${styles.section} text-center`}>
                          <h4 className="mb-4 pb-3 h4">چوونەژوورەوە</h4>
                          <div className={styles.form_group}>
                            <input
                              type="email"
                              name="L_UserName"
                              value={L_UserName}
                              onChange={(e) => setL_UserName(e.target.value)}
                              className={`${styles.form_style} fk`}
                              placeholder="تێپەڕە وشە"
                              id="logemail"
                              autoComplete="off"
                            />
                          </div>
                          <div className={`${styles.form_group} mt-2`}>
                            <input
                              type="password"
                              name="L_Password"
                              value={L_Password}
                              onChange={(e) => setL_Password(e.target.value)}
                              className={`${styles.form_style} fk`}
                              placeholder="وشەی نهێنی"
                              id="logpass"
                              autoComplete="off"
                            />
                          </div>
                          <button
                            className={`${styles.btn} mt-4 mb-3 fk`}
                            onClick={SignIn_User}
                          >
                            چوونەژوورەوە
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className={styles.card_back}>
                      <div className={styles.center_wrap}>
                        <div className={`${styles.section} text-center`}>
                          <h4 className="mb-2 pb-3 h4">بەشداربوون</h4>
                          <div className={styles.form_group}>
                            <input
                              type="text"
                              name="CompanyName"
                              value={CompanyName}
                              className={`${styles.form_style} fk`}
                              placeholder="ناوی کۆمپانیا (شوێن)"
                              id="CompanyName"
                              autoComplete="off"
                              onChange={(e) => setCompanyName(e.target.value)}
                            />
                          </div>
                          <div className={`${styles.form_group} mt-2`}>
                            <input
                              type="text"
                              name="OwnerName"
                              value={OwnerName}
                              className={`${styles.form_style} fk`}
                              placeholder="ناوی تەواو"
                              id="OwnerName"
                              autoComplete="off"
                              onChange={(e) => setOwnerName(e.target.value)}
                            />
                          </div>
                          <div className={`${styles.form_group} mt-2`}>
                            <input
                              type="text"
                              name="UserName"
                              value={UserName}
                              className={`${styles.form_style} fk`}
                              placeholder="تێپەڕە وشە"
                              id="UserName"
                              autoComplete="off"
                              onChange={(e) => setUserName(e.target.value)}
                            />
                          </div>
                          <div className={`${styles.form_group} mt-2`}>
                            <input
                              type="number"
                              name="phone_no1"
                              value={phone_no1}
                              className={`${styles.form_style} fk`}
                              placeholder="ژمارەی مۆبایل"
                              id="phone_no1"
                              autoComplete="off"
                              onChange={(e) => setphone_no1(e.target.value)}
                            />
                          </div>
                          <div className={`${styles.form_group} mt-2`}>
                            <input
                              type="password"
                              name="Password"
                              value={Password}
                              className={`${styles.form_style} fk`}
                              placeholder="وشەی نهێنی"
                              id="Password"
                              autoComplete="off"
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </div>
                          <button
                            className={`${styles.btn} mt-4 fk`}
                            onClick={SignUp_User}
                          >
                            چوونەژوورەوە
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
