import React from "react";
import { Breadcrumb } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { change_Lang } from "../../action/Helper_action";
const BreadcrumbLayout = ({ parent, title }) => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.HelperReducer.lang);
  return (
    <Breadcrumb
      style={{
        margin: "16px 0",
      }}
    >
      <Breadcrumb.Item className={`${lang === "en" ? "" : "fk"}`}>
        {dispatch(change_Lang(parent))}
      </Breadcrumb.Item>
      <Breadcrumb.Item className={`${lang === "en" ? "" : "fk"}`}>
        {dispatch(change_Lang(title))}
      </Breadcrumb.Item>
    </Breadcrumb>
  );
};

export default BreadcrumbLayout;
