import * as actions from "./Type";
import Swal from "sweetalert2";
import Instance from "../util/Instance";
import setToken from "../util/SetToken";

export const SignUp = (user) => {
  return async (dispatch) => {
    try {
      const res = await Instance.post(`/api/v1/SiginUp`, { ...user });
      if (res.status === 200) {
        const token = res.data.data.token;
        localStorage.setItem("token", token);
        dispatch({
          type: actions.SIGNUP_SUCCES,
          payload: {
            token,
          },
          isAuth: true,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    } catch (error) {
      dispatch({
        type: actions.SIGNUP_FAILURE,
        payload: {
          error: error,
        },
      });
      Swal.fire({
        title: "OPS",
        text: "Invalid of Phone or Password",
        icon: "error",
        html: `Please Check Phone Or Password`,
        confirmButtonText: "Ok",
        timer: 2000,
      });
    }
  };
};
export const Login = (user) => {
  return async (dispatch) => {
    try {
      const res = await Instance.post(`/api/v1/SignIn`, { ...user });
      if (res.status === 200) {
        const token = res.data.data.token;
        const User = res.data.data.User;
        localStorage.setItem("token", token);
        localStorage.setItem("User", JSON.stringify(User));
        dispatch({
          type: actions.LOGIN_SUCCESS,
          payload: {
            token,
            User,
          },
          isAuth: true,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      Swal.fire({
        title: "OPS",
        icon: "error",
        html: `<label class="fk">وشەی نهێنی یاخود تێپەڕە وشە هەڵەیە</label>`,
        confirmButtonColor: "#102770",
        confirmButtonText: "Ok",
        timer: 2000,
        iconColor: "darkred",
      });
    }
  };
};

export const isUserLoggedIn = () => {
  return async (dispatch) => {
    if (localStorage.token) {
      setToken(localStorage.getItem("token"));
    }
    const config = {
      "content-type": "application/json",
    };
    try {
      const res = await Instance.get(`/api/v1/User`, config);
      if (res.status === 200) {
        dispatch({
          type: actions.LOGIN_SUCCESS,
          payload: {
            Auth: true,
            User: res.data.data,
          },
          // L_status: true,
        });
      }
    } catch (error) {
      dispatch({
        type: actions.LOGIN_FAILURE,
        payload: {
          Auth: null,
        },
      });
      // localStorage.removeItem("token");
      // localStorage.removeItem("user");
      // window.location.reload();
    }
  };
};

export const Update_Profile = (user) => {
  return async (dispatch) => {
    if (localStorage.token) {
      setToken(localStorage.getItem("token"));
    }
    const config = {
      "content-type": "application/json",
    };

    try {
      const res = await Instance.put(`/api/v1/User`, { ...user }, config);
      if (res.status === 200) {
        const user = res.data.data.doc;
        localStorage.setItem("User", JSON.stringify(user));
        dispatch({
          type: actions.UPDATE_PROFILE_SUCCESS,
          payload: res.data.data.doc,
        });
        //   swal({
        //     title: "Good job!",
        //     text: "Update User Profile Successfuly !",
        //     icon: "success",
        //     timer: 1000,
        //   });
      }
    } catch (error) {}
  };
};
