import React, { useEffect, useState } from "react";
import { Button, Descriptions, Input } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { change_Lang } from "../../action/Helper_action";
import { Update_Profile, isUserLoggedIn } from "../../action/Auth_action";
import Swal from "sweetalert2";
const ProfileAccount = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.HelperReducer.lang);
  const user = useSelector((state) => state.AuthReducer.User);
  const [CompanyName, setCompanyName] = useState("");
  const [OwnerName, setOwnerName] = useState("");
  const [phone_no1, setphone_no1] = useState("");
  const [Password, setPassword] = useState("");
  const [UserName, setUserName] = useState("");
  useEffect(() => {
    setCompanyName(user && user.CompanyName);
    setOwnerName(user && user.OwnerName);
    setphone_no1(user && user.phone_no1);
    setUserName(user && user.UserName);
  }, [user]);

  const UpdateUser = () => {
    const data = {
      CompanyName,
      OwnerName,
      phone_no1,
      Password,
      CityId: "1",
      UserName,
    };
    if (
      CompanyName === "" ||
      OwnerName === "" ||
      phone_no1 === "" ||
      phone_no1 === 0
    ) {
      Swal.fire({
        title: "OPS",
        text: dispatch(change_Lang("Please Check Inputs")),
        icon: "error",
        html: `<b class=${lang === "en" ? "" : "fk"}>${dispatch(
          change_Lang("Please Check Inputs")
        )}</b>`,
        confirmButtonText: "Ok",
        buttonsStyling: { fontFamily: "fk" },
        confirmButtonColor: "#EA994A",
        timer: 2000,
        iconColor: "#363636",
      });
    } else {
      dispatch(Update_Profile(data));
      setTimeout(() => {
        dispatch(isUserLoggedIn());
      }, 2000);
      Swal.fire({
        title: "Good job!",
        html: `<b class="fk">${dispatch(
          change_Lang("You successfully tainted your work")
        )}</b>`,
        icon: "success",
        confirmButtonText: dispatch(change_Lang("success")),
        buttonsStyling: { fontFamily: "fk" },
        confirmButtonColor: "#EA994A",
        timer: 3000,
        iconColor: "#363636",
      });
    }
  };
  const items = [
    {
      key: "1",
      label: dispatch(change_Lang("Company Name")),
      children: (
        <label style={{ direction: "ltr", fontSize: 16 }}>
          {user.CompanyName}
        </label>
      ),
      className: lang === "en" ? "" : "fk",
    },
    {
      key: "2",
      label: dispatch(change_Lang("Full Name")),
      children: (
        <label style={{ direction: "ltr", fontSize: 16 }}>
          {user.OwnerName}
        </label>
      ),
      className: lang === "en" ? "" : "fk",
    },
    {
      key: "3",
      label: dispatch(change_Lang("Telephone")),
      children: (
        <label style={{ direction: "ltr", fontSize: 16 }}>
          {user.phone_no1}
        </label>
      ),
      className: lang === "en" ? "" : "fk",
    },
  ];
  return (
    <>
      <br /> <br />
      <Descriptions
        title={dispatch(change_Lang("Profile Account"))}
        labelStyle={{ color: "#EA994A", fontSize: 16, fontWeight: "bold" }}
        items={items}
        className={`${lang === "en" ? "text-left" : "text-right fk"}`}
      />
      <br /> <br />
      <div className="row">
        <div className="col-md-3">
          <label
            className={`${
              lang === "en"
                ? " d-flex justify-content-center"
                : "fk  d-flex justify-content-center"
            }`}
            style={{
              fontSize: "16px",
              color: "#EA994A",
              fontWeight: `${lang === "en" ? "" : "bold"}`,
            }}
          >
            {dispatch(change_Lang("Company Name"))}
          </label>
          <Input
            size="middle"
            value={CompanyName}
            name="CompanyName"
            type="text"
            autoFocus
            onChange={(e) => setCompanyName(e.target.value)}
            style={{
              fontSize: "16px",
            }}
          />
        </div>
        <div className="col-md-3">
          <label
            className={`${
              lang === "en"
                ? " d-flex justify-content-center"
                : "fk  d-flex justify-content-center"
            }`}
            style={{
              fontSize: "16px",
              color: "#EA994A",
              fontWeight: `${lang === "en" ? "" : "bold"}`,
            }}
          >
            {dispatch(change_Lang("Full Name"))}
          </label>
          <Input
            size="middle"
            value={OwnerName}
            name="OwnerName"
            type="text"
            onChange={(e) => setOwnerName(e.target.value)}
            style={{
              fontSize: "16px",
            }}
          />
        </div>
        <div className="col-md-3">
          <label
            className={`${
              lang === "en"
                ? " d-flex justify-content-center"
                : "fk  d-flex justify-content-center"
            }`}
            style={{
              fontSize: "16px",
              color: "#EA994A",
              fontWeight: `${lang === "en" ? "" : "bold"}`,
            }}
          >
            {dispatch(change_Lang("Telephone"))}
          </label>
          <Input
            size="middle"
            value={phone_no1}
            name="phone_no1"
            type="number"
            onChange={(e) => setphone_no1(e.target.value)}
            style={{
              fontSize: "16px",
            }}
          />
        </div>
        <div className="col-md-3">
          <label
            className={`${
              lang === "en"
                ? " d-flex justify-content-center"
                : "fk  d-flex justify-content-center"
            }`}
            style={{
              fontSize: "16px",
              color: "#EA994A",
              fontWeight: `${lang === "en" ? "" : "bold"}`,
            }}
          >
            {dispatch(change_Lang("Password"))}
          </label>
          <Input
            size="middle"
            value={Password}
            name="Password"
            type="text"
            onChange={(e) => setPassword(e.target.value)}
            style={{
              fontSize: "16px",
            }}
          />
        </div>
      </div>
      <br /> <br />
      <div className="row">
        <div className="col-12 d-flex justify-content-end form-inline">
          <Button
            className="ml-4 fk px-3"
            type="primary"
            shape="default"
            style={{
              background: "#000",
              color: "#fff",
            }}
            htmlType="submit"
            onClick={UpdateUser}
          >
            {dispatch(change_Lang("Update"))}
          </Button>
        </div>
      </div>
    </>
  );
};
export default ProfileAccount;
