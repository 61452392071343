export const lang = [
  {
    user_key: "Dashboard",
    kurdish: "پەڕەی سەرەکی",
    arabic: "الصفحة الرئيسية",
    english: "Dashboard",
  },
  {
    user_key: "Add Product",
    kurdish: "زیادکردن",
    arabic: "أضف منتج",
    english: "Add Product",
  },
  {
    user_key: "List Products",
    kurdish: "لیستی بەرهەمەکان",
    arabic: "قائمة المنتجات",
    english: "List Products",
  },
  //
  {
    user_key: "Product Brand",
    kurdish: "بڕاندی بەرهەمەمەکە",
    arabic: "العلامة التجارية للمنتج",
    english: "Product Brand",
  },
  {
    user_key: "Product Name",
    kurdish: "ناوی بەرهەمەمەکە",
    arabic: "اسم المنتج",
    english: "Product Name",
  },
  {
    user_key: "Quantity",
    kurdish: "دانە",
    arabic: "العدد",
    english: "Quantity",
  },
  {
    user_key: "price",
    kurdish: "نرخ",
    arabic: "السعر",
    english: "price",
  },
  {
    user_key: "Currency Type",
    kurdish: "جۆری دراو",
    arabic: "نوع العملة",
    english: "Currency Type",
  },
  {
    user_key: "Currency",
    kurdish: "دراو",
    arabic: "العملة",
    english: "Currency",
  },
  {
    user_key: "dinar",
    kurdish: "دینار",
    arabic: "دینار",
    english: "dinar",
  },
  {
    user_key: "dolar",
    kurdish: "دۆلار",
    arabic: "دولار",
    english: "dolar",
  },
  {
    user_key: "Description",
    kurdish: "دەربارەی بەرهەمەمەکە",
    arabic: "وصف",
    english: "Description",
  },
  {
    user_key: "Product Image",
    kurdish: "تۆمارکردنی وێنە",
    arabic: "تحميل صورة",
    english: "Product Image",
  },
  {
    user_key: "Create a new Product",
    kurdish: "تۆمارکردنی بەرهەمی نوێ",
    arabic: "تحديث المنتج",
    english: "Create a new Product",
  },
  {
    user_key: "Update Product",
    kurdish: "دەستکاری کردنی بەرهەمەکەت",
    arabic: "تسجيل منتجات جديدة",
    english: "Update Product",
  },
  {
    user_key: "Choose",
    kurdish: "هەلبژێرە",
    arabic: "إختر",
    english: "Choose",
  },
  {
    user_key: "Add",
    kurdish: "زیادکردن",
    arabic: "إضافة",
    english: "Add",
  },
  {
    user_key: "cancel",
    kurdish: "پاشگەزبوون",
    arabic: "إلغاء",
    english: "cancel",
  },
  {
    user_key: "date",
    kurdish: "بەروار",
    arabic: "التاريخ",
    english: "Date",
  },
  {
    user_key: "Image",
    kurdish: "وێنە",
    arabic: "صورة",
    english: "Image",
  },
  {
    user_key: "Action",
    kurdish: "کردارەکان",
    arabic: "افعال",
    english: "Action",
  },
  {
    user_key: "Search",
    kurdish: "گەڕان",
    arabic: "بحث",
    english: "Search",
  },
  {
    user_key: "Languages",
    kurdish: "زمانەکان",
    arabic: "اللغات",
    english: "Languages",
  },
  {
    user_key: "Profile Account",
    kurdish: "پەڕەی کەسی",
    arabic: "الملف الشخصي",
    english: "Profile Account",
  },
  {
    user_key: "Print",
    kurdish: "چاپکردن",
    arabic: "طباعة",
    english: "Print",
  },
  //
  {
    user_key: "UserName",
    kurdish: "پەڕەی کەسی",
    arabic: "الملف الشخصي",
    english: "UserName",
  },
  {
    user_key: "Telephone",
    kurdish: "ژمارەی مۆبایل",
    arabic: "رقم الهاتف",
    english: "Telephone",
  },
  {
    user_key: "Company Name",
    kurdish: "ناوی کۆمپانیا (شوێن)",
    arabic: "اسم الشركة",
    english: "Company Name",
  },
  {
    user_key: "Full Name",
    kurdish: "ناوی تەواو",
    arabic: "الاسم الكامل",
    english: "Full Name",
  },
  {
    user_key: "Password",
    kurdish: "وشەی نهێنی",
    arabic: "كلمة المرور",
    english: "Password",
  },
  {
    user_key: "Update",
    kurdish: "نویکردنەوە",
    arabic: "تحديث",
    english: "Update",
  },
  {
    user_key: "You successfully tainted your work",
    kurdish: "کارەکەت بە سەرکەوتوویی تەواوکرد.",
    arabic: "لقد نجحت في تلويث عملك",
    english: "You successfully tainted your work",
  },
  {
    user_key: "success",
    kurdish: "سەرکەوتووبوو",
    arabic: "ناجحا",
    english: "success",
  },
  {
    user_key: "Please Check Inputs",
    kurdish: "تکایە خانەکان پڕبکەوە",
    arabic: "الرجاء تعبئة الخلايا",
    english: "Please Check Inputs",
  },
  {
    user_key: "Are you sure",
    kurdish: "ئایا دڵنیایت ؟",
    arabic: "هل أنت متأكد ؟",
    english: "Are you sure?",
  },
  {
    user_key: "You won't be able to delete this!",
    kurdish: "دەتەوێ بیسڕیتەوە",
    arabic: "تريد حذفه",
    english: "You want to delete it.!",
  },
  {
    user_key: "Yes, delete it",
    kurdish: "بەڵێ، بسڕەوە",
    arabic: "نعم، احذفه",
    english: "Yes, delete it",
  },
  //
  {
    user_key: "Log out",
    kurdish: "چونەدەرەوە",
    arabic: "تسجيل الخروج",
    english: "Log out",
  },
];

export default lang;
