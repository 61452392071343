import * as actions from "./Type";
import lang from "../assets/data/translation";
export const Change_LangugeHeader = (lang) => {
  return (dispatch) => {
    if (lang === "Kurdish") {
      dispatch({
        type: actions.CHANGE_LANGE_KURDISH,
        payload: "krd",
      });
    } else if (lang === "Arabic") {
      dispatch({
        type: actions.CHANGE_LANGE_ARABIC,
        payload: "arb",
      });
    } else if (lang === "English") {
      dispatch({
        type: actions.CHANGE_LANGE_ENGLISH,
        payload: "en",
      });
    }
  };
};
export const change_Lang = (key, el) => {
  return (dispatch, getState) => {
    key = lang.find((item) => item.user_key === key);
    if (getState().HelperReducer.lang === "krd") {
      return key != null ? (key = key.kurdish) : el;
    } else if (getState().HelperReducer.lang === "arb") {
      return key != null ? (key = key.arabic) : el;
    } else if (getState().HelperReducer.lang === "en") {
      return key != null ? (key = key.english) : el;
    }
  };
};
