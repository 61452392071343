import { ConfigProvider } from "antd";
import LayoutSystem from "./components/Layout/LayoutSystem";
import Login from "./components/forms/Login";
import ListProducts from "./components/products/ListProducts";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import ProfileAccount from "./components/profile/ProfileAccount";
import { useEffect } from "react";
import { isUserLoggedIn } from "./action/Auth_action";
import Qrcode from "./components/products/Qrcode";
import PrintQR from "./components/products/PrintQR";
function App() {
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.AuthReducer.isAuth);
  const lang = useSelector((state) => state.HelperReducer.lang);
  const token = window.localStorage.getItem("token");
  useEffect(() => {
    if (token) {
      dispatch(isUserLoggedIn());
    }
  }, [isAuth]);
  return (
    <>
      <ConfigProvider direction={lang === "en" ? "ltr" : "rtl"}>
        <Router>
          <div className="App">
            {!token && <Redirect from="*" to="/login" />}
            <Switch>
              <Route path={`/login`} component={Login} />
              <Route exact path={`/qrcode-print`} component={PrintQR} />
              <LayoutSystem>
                <Route exact path={`/list-products`} component={ListProducts} />
                <Route
                  exact
                  path={`/profile-account`}
                  component={ProfileAccount}
                />
                <Route exact path={`/qrcode-image`} component={Qrcode} />
              </LayoutSystem>
            </Switch>
          </div>
        </Router>
      </ConfigProvider>
    </>
  );
}

export default App;
