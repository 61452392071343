import * as actions from "../action/Type";
const intialState = {
  lang: "krd",
};
const HelperReducer = (state = intialState, action) => {
  switch (action.type) {
    case actions.CHANGE_LANGE_KURDISH:
      return {
        ...state,
        lang: action.payload,
      };
    case actions.CHANGE_LANGE_ENGLISH:
      return {
        ...state,
        lang: action.payload,
      };
    case actions.CHANGE_LANGE_ARABIC:
      return {
        ...state,
        lang: action.payload,
      };
    default:
      return { ...state };
  }
};

export default HelperReducer;
