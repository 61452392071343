import React from "react";
import { Layout } from "antd";
const { Content } = Layout;
const ContentLayout = ({ children }) => {
  return (
    <Content
      style={{
        margin: "0 16px",
      }}
    >
      <div
        style={{
          minHeight: 360,
          // background: colorBgContainer,
        }}
      >
        {children}
      </div>
    </Content>
  );
};

export default ContentLayout;
