import { Button } from "antd";
import React, { Component } from "react";
import styled from "styled-components";
class ShowImageModel extends Component {
  render() {
    const { ImagePath, Close_Models, OnDelete_Image } = this.props;
    return (
      <ModalContainer onClick={() => Close_Models()}>
        <div className="container">
          <div className="row">
            <div
              id="modal"
              className="col-lg-8 mx-auto col-md-6 col-lg-4 text-center text-capitalize p-5"
            >
              <img
                src={ImagePath}
                style={{ width: "1600px", height: "500px" }}
                alt="product img"
                className="img-fluid"
              />
              <Button
                className="mt-3"
                type="primary"
                shape="default"
                style={{
                  background: "darkred",
                  color: "#fff",
                }}
                htmlType="submit"
                onClick={() => OnDelete_Image(ImagePath)}
              >
                Delete&nbsp;<i class="fa fa-trash-o" aria-hidden="true"></i>
              </Button>
            </div>
          </div>
        </div>
      </ModalContainer>
    );
  }
}

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  #modal {
    background: var(--mainWhite);
  }
`;

export default ShowImageModel;
