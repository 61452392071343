// Auth Types
export const SIGNUP_SUCCES = "SIGNUP_SUCCES";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";

// Helper Action
export const CHANGE_LANGE_KURDISH = "CHANGE_LANGE_KURDISH";
export const CHANGE_LANGE_ARABIC = "CHANGE_LANGE_ARABIC";
export const CHANGE_LANGE_ENGLISH = "CHANGE_LANGE_ENGLISH";
