import * as actions from "../action/Type";
const intialState = {
  token: "",
  error: "",
  User: {},
  isAuth: null,
  L_status: false,
};
const AuthReducer = (state = intialState, action) => {
  switch (action.type) {
    case actions.SIGNUP_SUCCES:
      return {
        ...state,
        token: action.payload.token,
        isAuth: true,
        // L_status: action.L_status,
      };
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        User: action.payload.User,
        token: action.payload.token,
        isAuth: true,
        // L_status: action.L_status,
      };
    case actions.LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    case actions.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return { ...state };
  }
};

export default AuthReducer;
